.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// ================================================================================

$speedJump: 1.5s;

.jump {
  animation: jump $speedJump infinite ease;

  @keyframes jump {
    0% {
      top: 0;
    }

    50% {
      top: -40px;
    }

    100% {
      top: 0;
    }
  }
}

.coin {
  padding: 1em;
  margin: 1.25em;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 150px;
  width: 150px;
}

.coin .front,
.coin .back {
  position: absolute;
  height: 150px;
  width: 150px;
  background-image: url(./SimpleGlassLogoV2.png);
  background-size: cover;
  border-radius: 50%;
  // border-top: 7px solid #b1ce9f;
  // border-left: 7px solid #b1ce9f;
  // border-right: 7px solid #70a84c;
  // border-bottom: 7px solid #70a84c;
  background-color: white;
  // box-shadow: 0 0px 40px, 0 0 2px;
  // box-shadow: 1px 1px 2px black, 0 0 25px #b1ce9f, 0 0 5px #70a84c;
  // box-shadow: 1px 1px 2px black, 0 0 30px #b1ce9f, 0 0 10px #70a84c;

  // .top {
  //   font-size: 30px;
  //   color: #d67f08;
  //   text-align: center;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  // }

  // .bottom {
  //   font-size: 30px;
  //   color: #d67f08;
  //   text-align: center;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  // }
}

.coin .shadow {
  width: 100%;
  height: 20px;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  bottom: -50px;
  border-radius: 50%;
  z-index: -1;
  margin: 185px 7px 0 7px;
  animation: swift $speedJump infinite ease;

  @keyframes swift {
    0% {
      opacity: 0.8;
    }

    50% {
      opacity: 0.4;
      transform: scale(0.8);
    }

    100% {
      opacity: 0.8;
    }
  }
}
.companyName .comingSoon {
  text-align: center;
  // position: relative;
}

.contact {
  font-size: calc(10px + 4vmin);
  a {
    text-decoration: none;
    color: #70a84c;
    &:hover {
      color: #b1ce9f;
    }
  }
}

.neon-sign {
  text-align: center;
  // width: 65%;
  // height: 3em;
  // margin: auto;
  // position: absolute;
  margin: 1em 0.5em;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  user-select: none;
}

.neon-sign b {
  font: 400 9vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #70a84c, 0 0 0.5em #70a84c,
    0 0 0.1em #70a84c, 0 10px 3px #000;
}
.neon-sign b span {
  animation: blink linear infinite 2s;
}
.neon-sign b span:nth-of-type(2) {
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79% {
    color: #333;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}

// +++++

.companyName {
  text-shadow: 1px 1px 2px black, 0 0 25px #b1ce9f, 0 0 5px #70a84c;
}
// ================================================================================

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 6vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
